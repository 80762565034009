import miVecinoLogo from '../../assets/images/mi-vecino-pichincha-logo.png'
import bancaWeb from '../../assets/images/bp-banca-web-icon.png'
import bancaMovil from '../../assets/images/bp-banca-movil-icon.png'
import ventanilla from '../../assets/images/bp-ventanilla-icon.png'

export default function PagoCorresponsal({ cerrar }) {
    return <div className="modal-otros-metodos-content" >
        <div className="modal-dialog modal-dialog-scrollable modal-lg modal-otros-metodos">
            <div className="modal-content">
                <div className="modal-header d-flex justify-content-between">
                    <h3 className="modal-title fs-5" id="corresponsalLabel">RECAUDACIONES CON CORRESPONSAL
                        BANCARIO</h3>
                    <button type="button" className="btn-close" onClick={() => cerrar()} aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <div className="row mb-2 pich">
                        <div className="col-md-3 pich mb-2"><img style={{ width: "100px" }} src={ miVecinoLogo } alt='mi vecino'/>
                        </div>
                        <div className="col-md-9">
                            <ol>
                                <li>Visita tu corresponsal Mi Vecino más cercano.</li>
                                <li>Indica que es un pago para <strong>COSTANETPLUS</strong>, Código
                                    <strong>69912</strong>
                                </li>
                                <li>Realiza el pago en efectivo indicando tu número de cédula.</li>
                                <li>Una vez terminado el proceso recibirás el comprobante de la transacción.</li>
                            </ol>
                        </div>
                    </div>
                    <div className="row mb-2 pich">
                        <div className="col-md-3"><img style={{ width: "100px" }} src={ bancaWeb } alt='banca web'/><br/>BANCA
                            WEB</div>
                        <div className="col-md-9">
                            <ol>
                                <li>Ingresa a www.pichincha.com con tu usuario y contraseña.</li>
                                <li>{'Selecciona la opción pagos > Servicios - Facturas > Nuevo pago > Elige la empresa'}
                                    <strong>TELECOM NET</strong>, según tu requerimiento.
                                </li>
                                <li>Si tienes una factura por pagar, ingresa en el campo contrapartida tu número de
                                    cédula, el monto a pagar y una descripción.</li>
                            </ol>
                        </div>
                    </div>
                    <div className="row mb-2 pich">
                        <div className="col-md-3 pich"><img style={{ width: "100px" }} src={ bancaMovil } alt='banca movil'/><br />BANCA MÓVIL</div>
                        <div className="col-md-9">
                            <ol>
                                <li>Ingresa a la Banca móvil con tu usuario y contraseña, Huella dactilar o PIN</li>
                                <li>{'Selecciona la opción Pagar servicios > Ingresa la empresa'} <strong>TELECOM
                                    NET</strong> en el buscador.</li>
                                <li>Completa el formulario: contrapartida (número de cédula) y descripción.</li>
                                <li>Ingresa el monto a pagar y completa los datos.</li>
                            </ol>
                        </div>
                    </div>
                    <div className="row mb-2 pich">
                        <div className="col-md-3 pich"><img style={{ width: "100px" }} src={ ventanilla } alt='ventanilla'/><br />VENTANILLAS</div>
                        <div className="col-md-9">
                            <p>Visita tu agencia Banco Pichincha más cercana.</p>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" onClick={() => cerrar()}>Cerrar</button>
                </div>
            </div>
        </div>
    </div>
}