import { useState } from 'react'
import ProgressSteeps from './ProgressSteeps'
import FormDatosServicios from './FormDatosServicio'
import FormDatosTarjeta from './FormDatosTarjeta'
import ResultadoTransaccion from './ResultadoTransaccion'
import InfoValoresPagos from './InfoValoresPagos'

export default function ModalProcesoPago({ cerrar, servicio, isResult, datosResult = {} }) {

    const [currentSteep, setCurrentSteep] = useState(1)
    const [idCheckout, setIdCheckout] = useState('')

    useState(() => {
        if (isResult) {
            setCurrentSteep(3)
        }
    }, [])

    const titleModal = () => {
        switch (currentSteep) {
            case 1:
                return 'COMPLETA LOS DATOS DE PAGO'
            case 2:
                return 'PAGO CON TARJETA DE CRÉDITO / DÉBITO'
            case 3:
                return 'RESUMEN DE TU PAGO'
            default:
                return 'COMPLETA LOS DATOS DE PAGO'
        }
    }

    function toSteepTwo(id) {
        setIdCheckout(id)
        setCurrentSteep(2)
    }

    return <div className="modal-otros-metodos-content">
        <div className="modal-dialog modal-dialog-scrollable modal-lg modal-otros-metodos" style={{ minWidth: currentSteep > 1  ? '60%'  : 'auto' }}>
            <div className="modal-content">
                <div className="modal-header d-flex justify-content-between">
                    <div className="modal-title fs-5 text-center" id="exampleModalLabel">{ titleModal() }</div>
                    {currentSteep !== 2 && <button type="button" className="btn-close" onClick={() => cerrar()} ></button>}
                </div>
                <div className="modal-body">
                    <ProgressSteeps currentStepp={ currentSteep } />
                    { (currentSteep === 1 || currentSteep === 2) && <InfoValoresPagos datos={ servicio } />}
                    <small className="salida row"></small>
                    { currentSteep === 1 && <FormDatosServicios servicio={ servicio } nextSteep={ (id) => toSteepTwo(id) } />}
                    { currentSteep === 2 && <FormDatosTarjeta id={ idCheckout } />}
                    { currentSteep === 3 && <ResultadoTransaccion datos={ datosResult } />}
                </div>
                <div className="modal-footer">
                    {currentSteep === 1 && <button type="button" className="btn btn-secondary" onClick={() => cerrar()}>Cerrar</button>}
                </div>
            </div>
        </div>
    </div>
}