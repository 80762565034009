

export default function ProgressSteeps({ currentStepp }) {

    const progress = currentStepp => {
        switch (currentStepp) {
            case 1:
                return 0;
            case 2:
                return 50;
            case 3:
                return 100;
            default:
                return 0;
        }
    }

    return <div className="cont-progress">
        <div className="progress-num">
            <div className="progress-num__item num-steep-active">1</div>
            <div className="progress-num__text progress-text-active">Completa tus datos</div>
        </div>
        <div className="progress-num">
            <div className={ `progress-num__item${ currentStepp >= 2  ? ' num-steep-active' : '' }` }>2</div>
            <div className={ `progress-num__text${ currentStepp >= 2 ? ' progress-text-active' : '' }` }>Elige tu forma de pago</div>
        </div>
        <div className="progress-num">
            <div className={ `progress-num__item${ currentStepp === 3 ? ' num-steep-active' : '' }` }>3</div>
            <div className={ `progress-num__text${ currentStepp === 3 ? ' progress-text-active' : '' }` }>Confirma tu pago</div>
        </div>
        <div className="progress-bar">
            <div className="progress-bar__item" style={{ width: `${ progress(currentStepp) }%` }}></div>
        </div>
    </div>
}