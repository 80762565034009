import { useEffect } from "react"

export default function FormDatosTarjeta({ id }) {

    const isPorduction = true;
    const url = isPorduction ? 'https://eu-prod.oppwa.com/v1/paymentWidgets.js' : 'https://test.oppwa.com/v1/paymentWidgets.js'

    useEffect(() => {
        const script = document.createElement('script');
        script.src = `${url}?checkoutId=${id}`;
        script.type = 'text/javascript';
        script.async = true;
        document.body.appendChild(script);

        /*const handleBeforeUnload = (e) => {
            e.preventDefault();
            e.returnValue = 'Si la página se recarga o la cierras tu pago podría no registrase en nuestro sistema';
        };
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };*/
        //window.location.href = 'http://localhost:3000/?resourcePath=/v1/checkouts/79E1E1EBB41134A257CB8D22280D6BBC.uat01-vm-tx01/payment'
        return () => {
            document.body.removeChild(script);
        };
    }, [url, id])

    return <div className="card">
        <div className="card-header">
            <div className="row">
                <div className="col-md-12">No cierres o recargues esta página<br /><small className="color-rojo">{'Si la página se recarga o la cierras tu pago podría no registrase en nuestro sistema'}</small></div>
            </div>
        </div>
        <div className="card-body">
            <form action={window.location.href} className="paymentWidgets" data-brands="VISA MASTER DINERS DISCOVER AMEX"></form>
            <small class="text-center politicas">Al pagar aceptas nuestras 
            <a target="_blank" href="https://www.costanetplus.net/politicas-de-privacidad-pagos-en-linea/" rel="noreferrer"> Políticas de privacidad </a> | 
            <a target="_blank" href="https://www.costanetplus.net/terminos-condiciones-pagos-en-linea/" rel="noreferrer">Términos y Condiciones de Pago</a></small>
        </div>
    </div>
}