import { useForm } from "react-hook-form"
import { urlServer } from "../providers/providers"
import { useState } from "react"
import { LoadingOval } from "./Loadings"

export default function FormIndexCedula({ setEsCliente, setDatosCliente, setCuentaConDeuda }) {

    const [ isLoading, setIsLoading ] = useState(false)
    const { register, handleSubmit, formState: { errors }, setError } = useForm()

    const onSubmit = handleSubmit(data => {
        let formData = new FormData()
        formData.append('cedula', data.cedula)
        setIsLoading(true)

        fetch(`${urlServer}/apiweb/pagos/getCustomerInfo`, {
            method: 'POST',
            body: formData
        }).then(response => response.json())
            .then(data => {
                if (data.ok) {
                    setDatosCliente(data.servicios)
                    setEsCliente(true)
                    setCuentaConDeuda(data.cuentaConDeuda)
                } else {
                    setError('cedula', { type: 'manual', message: data.error ?? 'Servicio no disponible por el momento, intenta mas tarde.' })
                }
            })
            .catch(e => setError('cedula', { type: 'manual', message: 'Servicio no disponible por el momento, intenta mas tarde' }))
            .finally(_ => setIsLoading(false))
    })

    return <form id="form" className="text-center pt-2" onSubmit={onSubmit}>
        <h4>Ingresa la Identificación del titular del servicio para consultar valores por pagar</h4>
        <div className="row g-2">
            <div className="col-sm-4">
                <label htmlFor="identificador">Cédula/Ruc/Pasaporte</label>
            </div>
            <div className="col-sm-4">
                <input type="number" className="form-control" placeholder="Cédula/Id/Pasaporte registrado con el Plan" {...register("cedula", {
                    required: {
                        value: true,
                        message: 'Ingresa la Cédula o RUC'
                    },
                    pattern: {
                        value: /^[0-9]{10}([0-9]{3})?$/,
                        message: 'La Cédula debe tener 10 dígitos o el RUC 13 dígitos'
                    },
                })} />
                {errors.cedula && <p className="error-input">{errors.cedula.message}</p>}
            </div>
            <div className="col-sm-4 d-grid">
            { isLoading ? <LoadingOval show={ isLoading } text={ 'Verificando usuario...' } /> :
            <button className="btn btn-primary btn-red h-fit-content">CONSULTAR</button>}
            </div>
        </div>
    </form>
}