import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';

export default function InfoValoresPagos({ datos }) {
    return <div className="cont-info-pago-steeps">
        <h6>PAGO SERVICIO INTERNET COSTANETPLUS {datos.nombre} {`(${datos.id})`}</h6>
        <h6>{datos.plan}</h6>
        <table className="tabla-result">
            <tbody>
                <tr>
                    <td style={{ textAlign: 'end', width: '50%', paddingRight: '5px' }}>Subtotal:</td>
                    <td style={{ textAlign: 'start', width: '50%', paddingLeft: '5px' }}>${datos.infoPago.subtotal}</td>
                </tr>
                <tr>
                    <td style={{ textAlign: 'end', width: '50%', paddingRight: '5px' }}>{'IVA(15%):'}</td>
                    <td style={{ textAlign: 'start', width: '50%', paddingLeft: '5px' }}>${datos.infoPago.iva}</td>
                </tr>
                <tr>
                    <td style={{ textAlign: 'end', width: '50%', paddingRight: '5px' }}>Comisión:</td>
                    <td style={{ textAlign: 'start', width: '50%', paddingLeft: '5px', display: 'flex', alignItems: 'center', gap: 10 }}>
                        ${datos.infoPago.comision}
                        <div className="icon-tooltip" data-tooltip-id="info-comision" data-tooltip-place="right" data-tooltip-content="Valor por comisión y costos operativos">?</div>
                    </td>
                </tr>
                <tr>
                    <td style={{ textAlign: 'end', width: '50%', paddingRight: '5px', paddingBottom: '15px' }}><b>Total:</b></td>
                    <td style={{ textAlign: 'start', width: '50%', paddingLeft: '5px', paddingBottom: '15px', display: 'flex', alignItems: 'center', gap: 10 }}>
                        <b>${datos.infoPago.total}</b>
                        <div className="icon-tooltip" data-tooltip-id="info-total" data-tooltip-place="right" data-tooltip-content="Subtotal + Iva + Comisión">?</div>
                    </td>
                </tr>
            </tbody>
        </table>
        <Tooltip id="info-comision" />
        <Tooltip id="info-total" />
    </div>
}