import { useState } from 'react'
import imgPichincha from '../../assets/images/bco-pichincha-logo.png'
import imgGuayaquil from '../../assets/images/logo-bco-gye.png'

function IconCopy({ text }) {

    const iconCopy = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="#363636" d="M384 336H192c-8.8 0-16-7.2-16-16V64c0-8.8 7.2-16 16-16l140.1 0L400 115.9V320c0 8.8-7.2 16-16 16zM192 384H384c35.3 0 64-28.7 64-64V115.9c0-12.7-5.1-24.9-14.1-33.9L366.1 14.1c-9-9-21.2-14.1-33.9-14.1H192c-35.3 0-64 28.7-64 64V320c0 35.3 28.7 64 64 64zM64 128c-35.3 0-64 28.7-64 64V448c0 35.3 28.7 64 64 64H256c35.3 0 64-28.7 64-64V416H272v32c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V192c0-8.8 7.2-16 16-16H96V128H64z" /></svg>

    const iconCopySuccess = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="#56be00" d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" /></svg>

    const [icon, setIcon] = useState(0)

    function copyToClipboard(text) {
        const textarea = document.createElement('textarea');
        textarea.value = text;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        document.body.removeChild(textarea);
        setIcon(1)
        setTimeout(() => setIcon(0), 1000)
    }

    return <button className="copyToClipBoard btn btn-light btn-sm ms-3" onClick={() => copyToClipboard(text)} aria-label='copiar' title='copiar'>
        <div className='icon-copiar'>{icon === 0 ? iconCopy : iconCopySuccess}</div>
    </button>
}

export default function PagoTransferenciaDeposito({ cerrar }) {

    return <div className="modal-otros-metodos-content">
        <div className="modal-dialog modal-dialog-scrollable modal-lg modal-otros-metodos">
            <div className="modal-content">
                <div className="modal-header d-flex justify-content-between">
                    <h3 className="modal-title fs-5" id="transferenciasLabel">PAGO POR TRANSFERENCIA O DEPÓSITO BANCARIO
                    </h3>
                    <button type="button" className="btn-close" onClick={() => cerrar()} aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <p>Puedes realizar tus transferencias en los siguientes Bancos:</p>
                    <div className="row">
                        <div className="col-md-6">
                            <table className="table table-sm pich">
                                <tbody>
                                    <tr>
                                        <td><img style={{ width: '50px' }} src={imgPichincha} alt='logo banco pichincha' /></td>
                                        <td>BANCO PICINCHA</td>
                                    </tr>
                                    <tr>
                                        <td>Razón Social:</td>
                                        <td><b>Telecom&Net S.A.S.</b></td>
                                    </tr>
                                    <tr>
                                        <td>RUC:</td>
                                        <td><b>0993317500001</b><IconCopy text={'0993317500001'} /></td>
                                    </tr>
                                    <tr>
                                        <td>Cta. corriente:</td>
                                        <td><b>2100239012</b><IconCopy text={'2100239012'} /></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="col-md-6">
                            <table className="table table-sm gye">
                                <tbody>
                                    <tr>
                                        <td><img style={{ width: '50px' }} src={imgGuayaquil} alt='logo banco guayaquil' /></td>
                                        <td>BANCO GUAYAQUIL</td>
                                    </tr>
                                    <tr>
                                        <td>Razón Social:</td>
                                        <td><b>Telecomnet SAS</b></td>
                                    </tr>
                                    <tr>
                                        <td>RUC:</td>
                                        <td><b>0993317500001</b><IconCopy text={'0993317500001'} /></td>
                                    </tr>
                                    <tr>
                                        <td>Cta. corriente:</td>
                                        <td><b>48505864</b><IconCopy text={'48505864'} /></td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                        <div className="col-md-12">
                            <table className="table table-sm">
                                <tbody>
                                    <tr>
                                        <td>Teléfono:</td>
                                        <td><b>0969904000</b><IconCopy text={'0969904000'} /></td>
                                    </tr>
                                    <tr>
                                        <td>Correo:</td>
                                        <td><b>pagos@costanetplus.net</b><IconCopy text={'pagos@costanetplus.net'} /></td>
                                    </tr>
                                    <tr>
                                        <td>Dirección:</td>
                                        <td><b>KM. 24 VÍA A LA COSTA PARROQUIA CHONGÓN</b></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <p>
                            Una vez realizado el pago envíanos comprobante del depósito/transferencia y nombre del
                            Titular del servicio a:
                            <strong><a
                                href="https://api.whatsapp.com/send?phone=593969904000&amp;text=Hola%20CostanetPlus%20Quiero%20registrar%20un%20pago%20por%20TRANSFERENCIA.">0969904000</a>
                                (Whatsapp de Fact. y Cobranzas Costanet+) </strong>
                            o al email:<strong> <a
                                href="mailto:pagos@costanetplus.net">pagos@costanetplus.net</a></strong>
                        </p>
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" onClick={() => cerrar()}>Cerrar</button>
                </div>
            </div>
        </div>
    </div>
}