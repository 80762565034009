import { Navigate, Route, Routes } from "react-router-dom";
import Inicio from "./pages/Inicio";
import DistribuidorLogin from "./pages/DistribuidorLogin";
import { useEffect, useState } from "react";

function App() {

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [distribuidorInfo, setDistribuidorInfo] = useState({});

  useEffect(() => {
      const token = sessionStorage.getItem('distribuidorToken');
      const dataDistribuidor = sessionStorage.getItem('distribuidorData');
      if (token) {
        const objData = JSON.parse(dataDistribuidor)
        setDistribuidorInfo({
          nombre: objData.nombre,
          apellido: objData.apellido,
          sector: objData.sector,
          codigo: objData.codigo,
          cedula: objData.cedula,
          numTransacciones: objData.numTransacciones,
          establecimiento: objData.establecimiento
        })
        setIsAuthenticated(true);
      }
  }, []);

  const cerrarSesion = () => {
    sessionStorage.removeItem('distribuidorToken');
    setIsAuthenticated(false)
  };

  return (<>
    <Routes>
        <Route path="/" element={ <Inicio isDistribuidor={ isAuthenticated } distribuidorInfo={ distribuidorInfo } cerrarSesion={ () => cerrarSesion() }/> } />
        <Route path="/distribuidor" element={ isAuthenticated ? <Navigate to="/" /> : <DistribuidorLogin setIsAuthenticated={ (is) => setIsAuthenticated(is) } setDistribuidorInfo={ (info) => setDistribuidorInfo(info) }/>} />
        <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  </>);
}

export default App;
