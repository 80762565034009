import { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import logoCostanet from '../assets/images/logo-costanet-light-version-small.png'
import FormIndexCedula from "../components/FormIndexCedula";
import { urlServer } from "../providers/providers";
import ListaServicios from "../components/ListaServicios";
import ModalProcesoPago from "../components/ModalProcesoPago";
import GlobalLoading from "../components/GlobalLoading";
import ModalInstructivo from "../components/ModalInstructivo";
import { Helmet } from "react-helmet";
import ModalTransaccionesDistribuidor from "../components/ModalTransaccionesDistribuidor";

function Inicio({ isDistribuidor, distribuidorInfo, cerrarSesion }) {

    const navigate = useNavigate();
    const location = useLocation();

    const [tieneResourcePath, setTieneResourcePath] = useState(false)
    const [esCliente, setEsCliente] = useState(false)
    const [cuentaConDeuda, setCuentaConDeuda] = useState(false)
    const [showFormProgress, setShowFormProgress] = useState(false)
    const [showInstructivo, setShowInstructivo] = useState(false)
    const [servicios, setServicios] = useState([])
    const [servicio, setServicio] = useState({})
    const [datosResult, setDatosResult] = useState({})
    const [searchParams] = useSearchParams();
    const [loadingTransacion, setLoadingTransacion] = useState(false)
    const [mostrarTrxDist, setMostrarTrxDist] = useState(false)

    useEffect(() => {
        if (searchParams.has('resourcePath')) {
            setTieneResourcePath(true)
            let formData = new FormData()
            formData.append('path', searchParams.get('resourcePath'))

            setLoadingTransacion(true)
            fetch(`${urlServer}/apiweb/pagos/getResult`, {
                method: 'POST',
                body: formData
            }).then(response => response.json())
                .then(data => {
                    setDatosResult(data.result)
                    if (data.servicios.length > 0) {
                        setCuentaConDeuda(data.cuentaConDeuda)
                        setServicios(data.servicios)
                        setEsCliente(true)
                    }
                    setShowFormProgress(true)
                    const nuevaUrlSinQuery = location.pathname;
                    navigate(nuevaUrlSinQuery, { replace: true, state: { ...location.state } });
                })
                .finally(_ => setLoadingTransacion(false))
        }
    }, [location, navigate, searchParams])

    function processPayment(index) {
        setServicio(servicios[index])
        setShowFormProgress(true)
    }

    function cancelProcessPayment() {
        setShowFormProgress(false)
        setServicio({})
    }

    return <div className="app">
        <Helmet>
            <title>{ isDistribuidor ? 'PAGO DISTRIBUIDOR COSTANET+' : 'PAGO RÁPIDO COSTANET +' }</title> 
        </Helmet>
        {loadingTransacion && <GlobalLoading />}
        {showFormProgress && <ModalProcesoPago servicio={servicio} cerrar={() => cancelProcessPayment()} isResult={tieneResourcePath} datosResult={datosResult} />}
        {mostrarTrxDist && <ModalTransaccionesDistribuidor cerrar={ () => setMostrarTrxDist(false) } distribuidor={ distribuidorInfo }/>}
        <div className="header bg-costanet text-center">
            {isDistribuidor && <div className="cont-info-distribuidor">
                <p className="title-dist">Distribuidor: {`${distribuidorInfo.nombre} ${distribuidorInfo.apellido}`} - C.I.: { distribuidorInfo.cedula }</p>
                <div className="cont-info-box-dist">
                    <p><span>Codigo distribuidor: </span>{ distribuidorInfo.codigo }</p>
                    <p><span>Sector: </span>{ distribuidorInfo.sector }</p>
                    <p style={{ fontStyle: 'italic', textDecoration: 'underline', cursor: 'pointer' }} onClick={ () => setMostrarTrxDist(true) }>Transacciones Aprobadas: { distribuidorInfo.numTransacciones }</p>
                </div>
            </div>}
            <h1><img src={logoCostanet} alt="Logotipo CostanetPlus Version light" />{isDistribuidor ? 'PAGO DISTRIBUIDOR AUTORIZADO' : 'PAGO RÁPIDO'} </h1>
        </div>
        <div className="container pb-5">
            {!esCliente ? <FormIndexCedula setDatosCliente={datos => setServicios(datos)} setEsCliente={es => setEsCliente(es)} setCuentaConDeuda={tieneDeuda => setCuentaConDeuda(tieneDeuda)} /> :
                <ListaServicios servicios={servicios} processPayment={index => processPayment(index)} cuentaConDeuda={cuentaConDeuda} />
            }
        </div>
        {showInstructivo && <ModalInstructivo cerrar={() => setShowInstructivo(false)} />}
        <div className="cont-fixed-instructivo">
            <button className="btn-instructivo" onClick={() => setShowInstructivo(true)}>¿Como Pagar?</button>
            {!isDistribuidor && <button className="btn-instructivo" onClick={() => navigate('/distribuidor')}>¿Eres Distribuidor?</button>}
            {isDistribuidor && <button className="btn-instructivo" onClick={() => cerrarSesion() }>Cerrar sesión</button>}
        </div>
    </div>
}

export default Inicio;