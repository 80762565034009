export default function ResultadoTransaccion({ datos }) {

    const handleDownload = () => {
        window.open(datos.urlFactura, '_blank');
    };

    return <div className="card">
        <div className="card-body cont-resultado">
            <div className={`icon${ !datos.ok ? ' red-icon' : '' }`}>
                {datos.ok ? <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z" /></svg> : <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c-9.4 9.4-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0z" /></svg>}
            </div>
            <h3>{datos.message}</h3>
            { datos.ok && <p>Comprobante: {datos.comprobante}</p> }
            <h6>PAGO SERVICIO INTERNET COSTANETPLUS {datos.ok && datos.nombre} {datos.ok && `(${datos.idCliente})`}</h6>
            <h6>{datos.nombreProducto}</h6>
            <table className="tabla-result">
                <tbody>
                    <tr>
                        <td style={{ textAlign: 'end', width: '50%', paddingRight: '5px', paddingTop: '15px', paddingBottom: '15px' }}>
                            { datos.ok ? `Fecha y hora del pago:` : `--` }
                        </td>
                        <td style={{ textAlign: 'start', width: '50%', paddingLeft: '5px', paddingTop: '15px', paddingBottom: '15px' }}>{datos.fecha}</td>
                    </tr>
                    { datos.ok && <><tr>
                        <td style={{ textAlign: 'end', width: '50%', paddingRight: '5px' }}>Subtotal:</td>
                        <td style={{ textAlign: 'start', width: '50%', paddingLeft: '5px' }}>${datos.subtotal}</td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'end', width: '50%', paddingRight: '5px' }}>{'IVA(15%):'}</td>
                        <td style={{ textAlign: 'start', width: '50%', paddingLeft: '5px' }}>${datos.iva}</td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'end', width: '50%', paddingRight: '5px' }}>Comisión:</td>
                        <td style={{ textAlign: 'start', width: '50%', paddingLeft: '5px' }}>${datos.comision}</td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'end', width: '50%', paddingRight: '5px', paddingTop: '15px', paddingBottom: '15px' }}><b>Total:</b></td>
                        <td style={{ textAlign: 'start', width: '50%', paddingLeft: '5px', paddingTop: '15px', paddingBottom: '15px' }}><b>${datos.total}</b></td>
                    </tr></>}
                </tbody>
            </table>
            <div className="row g-2 cont-buttons">
                { datos.ok && <div className="col-sm-6">
                    <button className="btn btn-primary" onClick={handleDownload}>Descargar Comprobante</button>
                </div> }
                <div className="col-sm-6" style={!datos.ok ? { width: '100%' } : {}}>
                    <button className="btn btn-primary" onClick={ () => window.location.reload() }>¿Realizar otro pago?</button>
                </div>
            </div>
        </div>
    </div>
}