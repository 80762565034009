import { LoadingOval } from "./Loadings";
import { LogoRojoCostanet } from "./LogosCostanet";

export default function GlobalLoading() {
    return <div className="global-loading">
        <div className="global-loading__container">
            <div className="cont-logo-loading">
                <LogoRojoCostanet />
            </div>
            <p className="alerta-cerrar">No cierres o recargues esta ventana, tu pago no se registrará en nuestro sistema</p>
            <LoadingOval show={ true } text={ 'Validando Transacción...' } />
        </div>
    </div>
}