import { useState } from "react"
import PagoTransferenciaDeposito from "./modales-otros-pagos/PagoTransferenciaDeposito"
import PagoCorresponsal from "./modales-otros-pagos/PagoCorrecpondal";
import PagoOficinas from "./modales-otros-pagos/PagoOficinas";

export default function OtrosCanalesPagos() {

    const [ showPagosTranferencias, setShowPagosTransferencias ] = useState(false)
    const [ showPagosCorresponsal, setShowPagosCorresponsal ] = useState(false)
    const [ showPagosLocal, setShowPagosLocal ] = useState(false)

    return <div className="botonera exitoYPago pb-5">
        <div className="row g-2">
            <div className="col-sm-4">
                <div className="card text-center">
                    <div className="card-body">
                        <h5 className="card-title">PAGO POR TRANSFERENCIA O DEPÓSITO BANCARIO</h5>
                        <p className="card-text">Realiza una transferencia Bancaria</p>
                        <button className="btn btn-primary" onClick={ () => setShowPagosTransferencias(true) }><i
                            className="bi bi-cash-coin me-2"></i>Ver Datos Bancarios</button>
                    </div>
                </div>
            </div>
            <div className="col-sm-4">
                <div className="card text-center">
                    <div className="card-body">
                        <h5 className="card-title">CORRESPONSAL BANCARIO</h5>
                        <p className="card-text">Para tu servicio con Banco del Barrio, Mi vecino</p>
                        <button className="btn btn-primary" onClick={ () => setShowPagosCorresponsal(true) }><i
                            className="bi bi-piggy-bank me-2"></i>Conocer más</button>
                    </div>
                </div>
            </div>
            <div className="col-sm-4">
                <div className="card text-center">
                    <div className="card-body">
                        <h5 className="card-title">PAGO EN AGENCIAS</h5>
                        <p className="card-text">Paga tus valores en nuestras oficinas Costanet+</p>
                        <button className="btn btn-primary" onClick={ () => setShowPagosLocal(true) }><i
                            className="bi bi-cash-coin me-2"></i>Ver Puntos de Pago</button>
                    </div>
                </div>
            </div>
        </div>

        <div className="text-center btn-realod-result">
            <button className="btn btn-info" onClick={ () => window.location.reload() }><i
                className="bi bi-arrow-clockwise me-2"></i>Realizar otra consulta</button>
        </div>
        { showPagosTranferencias && <PagoTransferenciaDeposito cerrar={ () => setShowPagosTransferencias(false) } /> }
        { showPagosCorresponsal && <PagoCorresponsal cerrar={ () => setShowPagosCorresponsal(false) } /> }
        { showPagosLocal && <PagoOficinas cerrar={ () => setShowPagosLocal(false) } /> }
    </div>
}