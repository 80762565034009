import { useEffect, useState } from "react"
import { LoadingOval } from "./Loadings"
import { urlServer } from "../providers/providers"

function TablaTransacciones({ transacciones }) {
    return <>
        { transacciones.length > 0 ? <><table class="table table-trx-dist">
            <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Fecha</th>
                    <th scope="col">Total</th>
                    <th scope="col">Nombre</th>
                    <th scope="col">Apellido</th>
                    <th scope="col">Cédula</th>
                    <th scope="col">Transacción</th>
                    <th scope="col">Estado</th>
                </tr>
            </thead>
            <tbody>
                {transacciones?.map((transaccion) => <tr key={ transaccion.id }>
                    <th scope="row">{ transaccion.id }</th>
                    <td>{ transaccion.fecha }</td>
                    <td>{ transaccion.total }</td>
                    <td>{ transaccion.nombre }</td>
                    <td>{ transaccion.apellido }</td>
                    <td>{ transaccion.cedula }</td>
                    <td>{ transaccion.trx }</td>
                    <td>{ transaccion.status }</td>
                </tr>)}
            </tbody>
        </table><p className="text-ult-trx">Mostrando {transacciones.length} últimas transacciones aprobadas</p></>:
        <p className="no-transacciones">No hay transacciones realizadas</p>}
    </>
}

export default function ModalTransaccionesDistribuidor({ cerrar, distribuidor }) {

    const [loading, setLoading] = useState(true)
    const [ transacciones, setTransacciones ] = useState([])

    useEffect(() => {
        const token = sessionStorage.getItem('distribuidorToken');
        if (token) {
            let formData = new FormData()
            formData.append('token', token)
            fetch(`${urlServer}/apiweb/pagos/getTransaccionesDistribuidor`, {
                method: 'POST',
                body: formData
            }).then(response => response.json())
                .then(data => {
                    if (data.ok) {
                        setTransacciones(data.transacciones)
                    }
                })
                .finally(_ => setLoading(false))
        }
    }, [])

    return <div className="modal-otros-metodos-content">
        <div className="modal-dialog modal-dialog-scrollable modal-lg modal-otros-metodos">
            <div className="modal-content">
                <div className="modal-header d-flex justify-content-between">
                    <div className="modal-title fs-5 text-center titl-dist">Transacciones Distribuidor - {`${distribuidor.nombre} ${distribuidor.apellido} - ${distribuidor.establecimiento}`}</div>
                    <button type="button" className="btn-close" onClick={() => cerrar()} ></button>
                </div>
                <div className="modal-body">
                    <div className="row">
                        <div className="col-md-12">
                            {loading ? <LoadingOval show={loading} text={'Recuperando Transacciones Realizadas'} /> : <TablaTransacciones transacciones={ transacciones }/>}
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" onClick={() => cerrar()} >Cerrar</button>
                </div>
            </div>
        </div>
    </div>
}