import { useState } from "react"
import { useForm } from "react-hook-form"
import logoBlanco from '../assets/images/logo-blanco.png'

import '../assets/css/login-distribuidor.css'
import { urlServer } from "../providers/providers"
import { Helmet } from "react-helmet"

function Loading() {
  return (
    <div className="loading">
      <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" style={{margin: 'auto', display: 'block'}} width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
        <circle cx="75" cy="50" fill="#ff7c7c" r="6">
          <animate attributeName="r" values="3.5999999999999996;3.5999999999999996;6;3.5999999999999996;3.5999999999999996" times="0;0.1;0.2;0.3;1" dur="1.075268817204301s" repeatCount="indefinite" begin="-0.985663082437276s"></animate>
          <animate attributeName="fill" values="#ff7c7c;#ff7c7c;#e71414;#ff7c7c;#ff7c7c" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1.075268817204301s" begin="-0.985663082437276s"></animate>
        </circle><circle cx="71.65063509461098" cy="62.5" fill="#ff7c7c" r="6">
          <animate attributeName="r" values="3.5999999999999996;3.5999999999999996;6;3.5999999999999996;3.5999999999999996" times="0;0.1;0.2;0.3;1" dur="1.075268817204301s" repeatCount="indefinite" begin="-0.8960573476702509s"></animate>
          <animate attributeName="fill" values="#ff7c7c;#ff7c7c;#e71414;#ff7c7c;#ff7c7c" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1.075268817204301s" begin="-0.8960573476702509s"></animate>
        </circle><circle cx="62.5" cy="71.65063509461096" fill="#ff7c7c" r="6">
          <animate attributeName="r" values="3.5999999999999996;3.5999999999999996;6;3.5999999999999996;3.5999999999999996" times="0;0.1;0.2;0.3;1" dur="1.075268817204301s" repeatCount="indefinite" begin="-0.8064516129032258s"></animate>
          <animate attributeName="fill" values="#ff7c7c;#ff7c7c;#e71414;#ff7c7c;#ff7c7c" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1.075268817204301s" begin="-0.8064516129032258s"></animate>
        </circle><circle cx="50" cy="75" fill="#ff7c7c" r="6">
          <animate attributeName="r" values="3.5999999999999996;3.5999999999999996;6;3.5999999999999996;3.5999999999999996" times="0;0.1;0.2;0.3;1" dur="1.075268817204301s" repeatCount="indefinite" begin="-0.7168458781362007s"></animate>
          <animate attributeName="fill" values="#ff7c7c;#ff7c7c;#e71414;#ff7c7c;#ff7c7c" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1.075268817204301s" begin="-0.7168458781362007s"></animate>
        </circle><circle cx="37.50000000000001" cy="71.65063509461098" fill="#ff7c7c" r="6">
          <animate attributeName="r" values="3.5999999999999996;3.5999999999999996;6;3.5999999999999996;3.5999999999999996" times="0;0.1;0.2;0.3;1" dur="1.075268817204301s" repeatCount="indefinite" begin="-0.6272401433691757s"></animate>
          <animate attributeName="fill" values="#ff7c7c;#ff7c7c;#e71414;#ff7c7c;#ff7c7c" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1.075268817204301s" begin="-0.6272401433691757s"></animate>
        </circle><circle cx="28.34936490538903" cy="62.5" fill="#ff7c7c" r="6">
          <animate attributeName="r" values="3.5999999999999996;3.5999999999999996;6;3.5999999999999996;3.5999999999999996" times="0;0.1;0.2;0.3;1" dur="1.075268817204301s" repeatCount="indefinite" begin="-0.5376344086021505s"></animate>
          <animate attributeName="fill" values="#ff7c7c;#ff7c7c;#e71414;#ff7c7c;#ff7c7c" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1.075268817204301s" begin="-0.5376344086021505s"></animate>
        </circle><circle cx="25" cy="50" fill="#ff7c7c" r="6">
          <animate attributeName="r" values="3.5999999999999996;3.5999999999999996;6;3.5999999999999996;3.5999999999999996" times="0;0.1;0.2;0.3;1" dur="1.075268817204301s" repeatCount="indefinite" begin="-0.44802867383512546s"></animate>
          <animate attributeName="fill" values="#ff7c7c;#ff7c7c;#e71414;#ff7c7c;#ff7c7c" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1.075268817204301s" begin="-0.44802867383512546s"></animate>
        </circle><circle cx="28.34936490538903" cy="37.50000000000001" fill="#ff7c7c" r="6">
          <animate attributeName="r" values="3.5999999999999996;3.5999999999999996;6;3.5999999999999996;3.5999999999999996" times="0;0.1;0.2;0.3;1" dur="1.075268817204301s" repeatCount="indefinite" begin="-0.35842293906810035s"></animate>
          <animate attributeName="fill" values="#ff7c7c;#ff7c7c;#e71414;#ff7c7c;#ff7c7c" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1.075268817204301s" begin="-0.35842293906810035s"></animate>
        </circle><circle cx="37.499999999999986" cy="28.34936490538904" fill="#ff7c7c" r="6">
          <animate attributeName="r" values="3.5999999999999996;3.5999999999999996;6;3.5999999999999996;3.5999999999999996" times="0;0.1;0.2;0.3;1" dur="1.075268817204301s" repeatCount="indefinite" begin="-0.26881720430107525s"></animate>
          <animate attributeName="fill" values="#ff7c7c;#ff7c7c;#e71414;#ff7c7c;#ff7c7c" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1.075268817204301s" begin="-0.26881720430107525s"></animate>
        </circle><circle cx="49.99999999999999" cy="25" fill="#ff7c7c" r="6">
          <animate attributeName="r" values="3.5999999999999996;3.5999999999999996;6;3.5999999999999996;3.5999999999999996" times="0;0.1;0.2;0.3;1" dur="1.075268817204301s" repeatCount="indefinite" begin="-0.17921146953405018s"></animate>
          <animate attributeName="fill" values="#ff7c7c;#ff7c7c;#e71414;#ff7c7c;#ff7c7c" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1.075268817204301s" begin="-0.17921146953405018s"></animate>
        </circle><circle cx="62.5" cy="28.349364905389034" fill="#ff7c7c" r="6">
          <animate attributeName="r" values="3.5999999999999996;3.5999999999999996;6;3.5999999999999996;3.5999999999999996" times="0;0.1;0.2;0.3;1" dur="1.075268817204301s" repeatCount="indefinite" begin="-0.08960573476702509s"></animate>
          <animate attributeName="fill" values="#ff7c7c;#ff7c7c;#e71414;#ff7c7c;#ff7c7c" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1.075268817204301s" begin="-0.08960573476702509s"></animate>
        </circle><circle cx="71.65063509461096" cy="37.499999999999986" fill="#ff7c7c" r="6">
          <animate attributeName="r" values="3.5999999999999996;3.5999999999999996;6;3.5999999999999996;3.5999999999999996" times="0;0.1;0.2;0.3;1" dur="1.075268817204301s" repeatCount="indefinite" begin="0s"></animate>
          <animate attributeName="fill" values="#ff7c7c;#ff7c7c;#e71414;#ff7c7c;#ff7c7c" repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1.075268817204301s" begin="0s"></animate>
        </circle>
      </svg>
    </div>
  )
}

function DistribuidorLogin({ setIsAuthenticated, setDistribuidorInfo }) {

  const [passwordShown, setPasswordShown] = useState(false);
  const [errorApp, setErrorApp] = useState(null);
  const [isLoadig, setIsLoading] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const onSubmit = (data) => {
    let formData = new FormData()
    formData.append('password', data['password']);
    formData.append('cedula', data['cedula']);
    setIsLoading(true)
    fetch(`${urlServer}/apiweb/pagos/authDistribuidor`, {
        method: 'POST',
        body: formData,
    })
    .then((response) => response.json())
    .then((datos) => {
        if (datos.ok) {
            setDistribuidorInfo(datos)
            sessionStorage.setItem('distribuidorToken', datos.token);
            sessionStorage.setItem('distribuidorData', JSON.stringify(datos));
            setIsAuthenticated(true);
        } else {
          setErrorApp(datos.error)
        }
    })
    .catch(error => {
      console.error(error)
      setErrorApp('En este momento no se puede realizar la operación, intenta mas tarde o contacta a soporte')
    })
    .finally(setIsLoading(false))
  }

  return (
    <div className="body">
      <Helmet>
        <title>PAGO DISTRIBUIDOR COSTANET+</title>
      </Helmet>
    <div className="ancho-columna-600 cont-app">
      { isLoadig && <Loading/>}
      <img src={ logoBlanco } className='logo-app' />
      <h2 className="titulo-recuperar">Ingresa tus datos</h2>
      <form className='form-recuperar' onSubmit={handleSubmit(onSubmit)}>
        <div className='form-text-field'>
          <input type="text" placeholder='Código distribuidor / Cédula' {...register("cedula", { 
            required: {
              value: true,
              message: 'Completa los datos'
            }
            })} />
        </div>
        <div className='form-text-field'>
          <input type={passwordShown ? "text" : "password"} placeholder='Contraseña' {...register("password", { 
            required: {
              value: true,
              message: 'Ingresa la contraseña'
            }
            })} />
            <div className="cont-eye">
              <svg onClick={togglePasswordVisiblity} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"/></svg>
            </div>
        </div>
        <button className='btn-dis btn-primario' type="submit">Enviar</button>
      </form>
      {(!!errors['password'] || !!errors['cedula'] || !!errorApp) &&
      <div className="snackBar">
        <p>{!!errors['cedula'] && `• ${errors['cedula'].message}`}</p>
        <p>{!!errors['password'] && `• ${errors['password'].message}`}</p>
        <p>{!!errorApp && `• ${errorApp}`}</p>
      </div>}
    </div>
    </div>
  );
}

export default DistribuidorLogin;