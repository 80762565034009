import OtrosCanalesPagos from "./OtrosCanalesPago"

export default function ListaServicios({ servicios, processPayment, cuentaConDeuda = false }) {
    const numServicios = servicios.length

    function pagar(indexService) {
        processPayment(indexService)
    }
    return <><div className="row datos_preliminares salida">
        <div className="title-encontramos">Encontramos { numServicios } Servicio{ numServicios > 1 && 's' } para la cédula: '{ servicios[0].cedula }'</div>
        { servicios.map((servicio, index) => <div key={ index } className="col-md-12">
            <table className="shadow rounded table table-striped table-sm table-services">
                <thead className="table-light">
                    <tr>
                        <th className="text-uppercase title-service" colSpan="4">{ servicio.nombre }<i> {`(Id:${ servicio.id })`}</i></th>
                    </tr>
                </thead>
                <tbody className="pagadera">
                    <tr>
                        <td className="text-bold">Cedula/RUC: </td>
                        <td>{ servicio.cedula }</td>
                        <td className="text-bold">Direccion: </td>
                        <td>{ servicio.direccion }</td>
                    </tr>
                    <tr>
                        <td className="text-bold">Teléfono: </td>
                        <td>{ servicio.telefono }</td>
                        <td className="text-bold">Correo: </td>
                        <td>{ servicio.correo }</td>
                    </tr>
                    <tr>
                        <td className="text-bold">Plan: </td>
                        <td>{ servicio.plan }</td>
                        <td className="text-bold">Estado cliente: </td>
                        <td>{ servicio.estadoServicio }</td>
                    </tr>
                    <tr>
                        <td className="text-bold">Deuda:</td>
                        <td colSpan="3">$ { `${ servicio.deuda } (inc. IVA)`} <span className="no-registra-span">{ `${ !servicio.tieneDeuda ? ' - No registra valores pendientes' : '' }` }</span></td>
                    </tr>
                    { servicio.tieneDeuda ? <><tr>
                        <td className="text-bold"><i>Fech. Emisión Factura:</i></td>
                        <td>{ servicio.facturas[0].emitido }</td>
                        <td className="text-bold"><i>Pague hasta:</i></td>
                        <td>{ servicio.facturas[0].vencimiento }</td>
                    </tr>
                    <tr>
                        <td className="text-bold">FACTURA POR PAGAR:</td>
                        <td>{`${ servicio.facturas[0].id } (No pagado)`}</td>
                        <td colSpan="2"><button className="btn btn-primary btn-sm" onClick={ () => pagar(index) }><i className="bi bi-credit-card me-2"></i>¡Pagar Ahora!</button></td>
                    </tr></> : 
                    <tr>
                        <td className="text-bold">Última factura:</td>
                        <td colSpan="3">
                            { `${ servicio.ultimaFactura.id } (Pagado) `}
                            <a href={ servicio.ultimaFactura.url } target="_blank" rel="noreferrer">Ver comprobante</a>
                        </td>
                    </tr>}
                </tbody>
            </table>
        </div>)}
    </div>
    { !cuentaConDeuda && <p className="texto-recordatorio">Te recordamos nuestros canales de pago</p> }
    <OtrosCanalesPagos />
    </>
}