import { useForm } from 'react-hook-form'
import imgVerifiyDataFast from '../assets/images/verified.png'
import { useState } from 'react'
import { urlServer } from '../providers/providers'
import { LoadingOval } from './Loadings'

export default function FormDatosServicios({ servicio, nextSteep }) {

    const [errorSteep, setErrorSteep] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const { register, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            servicio: servicio.plan ?? 'Plan de Internet',
            idServicio: servicio.id ?? '',
            correo: servicio.correo ?? '',
            cedula: servicio.cedula ?? '',
            telefono: servicio.telefono ?? '',
            direccion: servicio.direccion ?? ''
        }
    })

    const onSubmit = handleSubmit(data => {
        const token = sessionStorage.getItem('distribuidorToken');
        let formData = new FormData()
        formData.append('primer_nombre', data.primer_nombre)
        formData.append('segundo_nombre', data.segundo_nombre)
        formData.append('apellido', data.apellido)
        formData.append('id', data.idServicio)
        formData.append('correo', data.correo)
        formData.append('cedula', data.cedula)
        formData.append('telefono', data.telefono)
        formData.append('direccion', data.direccion)
        formData.append('servicio', data.servicio)
        formData.append('token', token ? token : '')

        setIsLoading(true)
        fetch(`${urlServer}/apiweb/pagos/checkout`, {
            method: 'POST',
            body: formData
        }).then(response => response.json())
            .then(data => {
                console.log(data)
                if (data.ok) {
                    nextSteep(data.id)
                } else {
                    setErrorSteep(data.error ?? 'Servicio no disponible por el momento, intenta mas tarde.')
                }
            })
            .catch(e => setErrorSteep('Servicio no disponible por el momento, intenta mas tarde'))
            .finally(_ => setIsLoading(false))
    })

    return <div className="card">
        <div className="card-header">
            <div className="row">
                <div className="col-md-6">Completa el formulario con tus datos de Pago<br /><small>{'Nota: Todos los campos son requeridos(*)'}</small></div>
            </div>
        </div>
        <div className="card-body">
            <form className="datosUser row g-3" onSubmit={onSubmit}>
                <div className="col-md-6">
                    <label>{'Primer Nombre(*)'}</label>
                    <input className="primer_nombre form-control" type="text" placeholder="Primer nombre de quien remite el Pago" {...register("primer_nombre", {
                        required: {
                            value: true,
                            message: 'Este campo es requerido'
                        },
                    })} />
                    {errors.primer_nombre && <p className="error-input">{errors.primer_nombre.message}</p>}
                </div>
                <div className="col-md-6">
                    <label>{'Segundo Nombre(*)'}</label>
                    <input className="segundo_nombre form-control" type="text" placeholder="Segundo nombre quien remite el Pago" {...register("segundo_nombre", {
                        required: {
                            value: true,
                            message: 'Este campo es requerido'
                        },
                    })} />
                    {errors.segundo_nombre && <p className="error-input">{errors.segundo_nombre.message}</p>}
                </div>
                <div className="col-md-6">
                    <label>{'Apellido(*)'}</label>
                    <input className="apellido form-control" type="text" placeholder="apellido de quien remite el Pago" {...register("apellido", {
                        required: {
                            value: true,
                            message: 'Este campo es requerido'
                        },
                    })} />
                    {errors.apellido && <p className="error-input">{errors.apellido.message}</p>}
                </div>
                <div className="col-md-6">
                    <label>{'Email(*)'}</label>
                    <input className="email form-control" type="email" placeholder="email de quien remite el Pago" {...register("correo", {
                        required: {
                            value: true,
                            message: 'Este campo es requerido'
                        },
                        pattern: {
                            value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,
                            message: 'El correo ingresado no es válido'
                        },
                    })} />
                    {errors.correo && <p className="error-input">{errors.correo.message}</p>}
                </div>
                <div className="col-md-6">
                    <label>{'Cedula(*)'}</label>
                    <input className="cedula form-control" type="number" placeholder="cedula de quien remite el Pago" readOnly {...register("cedula", {
                        required: {
                            value: true,
                            message: 'Este campo es requerido'
                        },
                        pattern: {
                            value: /^[0-9]{10}([0-9]{3})?$/,
                            message: 'La Cédula debe tener 10 dígitos o el RUC 13 dígitos'
                        },
                    })} />
                    {errors.cedula && <p className="error-input">{errors.cedula.message}</p>}
                </div>
                <div className="col-md-6">
                    <label>{'Telefono(*)'}</label>
                    <input className="telefono form-control" type="text" placeholder="telefono de quien remite el Pago" {...register("telefono", {
                        required: {
                            value: true,
                            message: 'Este campo es requerido'
                        },
                        pattern: {
                            value: /^(?:0[2-7][2-9]\d{5}|09\d{8})$/,
                            message: 'El número de teléfono no tiene el formato correcto'
                        },
                    })} />
                    {errors.telefono && <p className="error-input">{errors.telefono.message}</p>}
                </div>
                <div className="col-12">
                    <label>{'Direccion del cliente(*)'}</label>
                    <input className="direccion_cliente form-control" type="text" placeholder="direccion_cliente de quien remite el Pago" {...register("direccion", {
                        required: {
                            value: true,
                            message: 'Este campo es requerido'
                        },
                        maxLength: {
                            value: 100,
                            message: 'La dirección no puede tener más de 100 caracteres'
                        }
                    })} />
                    {errors.direccion && <p className="error-input">{errors.direccion.message}</p>}
                </div>
                <div className="d-grid">
                    {  isLoading ? <LoadingOval show={ isLoading } text={ 'Procesando ...' } /> : <button className="btn btn-primary" type="submit">CONTINUAR</button> }
                    {errorSteep !== '' && <p className='error-steep'>{ errorSteep }</p>}
                    <img className="mt-3" style={{ maxWidth: '100%', display: 'block', margin: 'auto' }} src={imgVerifiyDataFast} alt='icons'/>
                </div>
            </form>
        </div>
    </div>
}