export default function ModalInstructivo({ cerrar }) {
    return <div className="modal-otros-metodos-content">
        <div className="modal-dialog modal-dialog-scrollable modal-lg modal-otros-metodos">
            <div className="modal-content">
                <div className="modal-header d-flex justify-content-between">
                    <div className="modal-title fs-5 text-center" id="exampleModalLabel">Instructivo ¿Cómo Pagar en línea?</div>
                    <button type="button" className="btn-close" onClick={() => cerrar()} ></button>
                </div>
                <div className="modal-body">
                    <div className="row">
                        <iframe width="315" height="560" src="https://www.youtube.com/embed/9ZYoxLbPGng?si=1n2OIMG1gl2RwGgZ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" onClick={() => cerrar()} >Cerrar</button>
                </div>
            </div>
        </div>
    </div>
}