import chongon from '../../assets/images/costanetplus-chongon.jpg'
import cerecita from '../../assets/images/costanetplus-cerecita.jpg'

export default function PagoOficinas({ cerrar }) {
    return <div className="modal-otros-metodos-content" >
        <div className="modal-dialog modal-dialog-scrollable modal-lg modal-otros-metodos">
            <div className="modal-content">
                <div className="modal-header d-flex justify-content-between">
                    <h3 className="modal-title fs-5" id="oficinasLabel">PAGOS EN AGENCIAS COSTANET+</h3>
                    <button type="button" className="btn-close" onClick={() => cerrar()} aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <p>
                        Acércate y cancela en efectivo en nuestras oficinas<br/>
                            Recuerda que nuestros horarios de atención son:
                            <ul>
                                <li>Lunes a Viernes de 08H00 a 17H30 </li>
                                <li>Sábados de 08H00 a 14H00</li>
                            </ul>
                    </p>
                    <div className="row">
                        <div className="col-md-6">
                            <table>
                                <tbody>
                                    <tr>
                                        <td><img style={{ width: '100px', marginRight: '10px' }} src={ chongon } alt='chongon' /></td>
                                        <td>
                                            <p>Sucursal Chongón​ Km 24 Vía a la Costa. Parroquia Chongon</p>
                                            <p><a className="btn btn-primary" target="_blank" rel="noreferrer"
                                                href="https://goo.gl/maps/YwUWQ33FYqyowHZY8">Ver en el mapa</a></p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="col-md-6">
                            <table>
                                <tbody>
                                    <tr>
                                        <td><img style={{ width: '100px', marginRight: '10px' }} src={ cerecita } alt='cerecita' /></td>
                                        <td>
                                            <p>Matriz Cerecita km 51 vía a la costa, calle principal antes de llegar al
                                                UPC - Edificio Costanet Cerecita</p>
                                            <p><a className="btn btn-primary" target="_blank" rel="noreferrer"
                                                href="https://goo.gl/maps/3MoyixNeaWJgs25b7">Ver en el mapa</a></p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" onClick={() => cerrar()}>Cerrar</button>
                </div>
            </div>
        </div>
    </div>
}